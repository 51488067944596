import React, { FC, Fragment } from "react";
import { ItemReceipt } from "@common/types/receipt";
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { Delete, Folder } from "@mui/icons-material";

interface IListItemReceiptProps {
    key: number
    itemReceipt: ItemReceipt;
    // editAction: (item: ItemReceipt) => void
    onDelete: () => void
}

export const countItem = (count: number | null | undefined) => {
    if (!count) {
        return null;
    }
    return <Fragment>
        <Typography
                sx={{ display: "inline", textAlign: "right" }}
                component="span"
                variant="body2"
                color="text.primary"
        >
            {count}
        </Typography>
        {"Кг"}
    </Fragment>;

};

export const ListItemReceipt: FC<IListItemReceiptProps> = ({ itemReceipt, onDelete }) => {
    return (
            <ListItem
                    secondaryAction={
                        <Stack direction="row" spacing={2}>
                            {/*<IconButton  edge="end" aria-label="edit">*/}
                            {/*    <Edit/>*/}
                            {/*</IconButton>*/}
                            <IconButton onClick={onDelete} edge="end"
                                        aria-label="delete">
                                <Delete/>
                            </IconButton>
                        </Stack>
                    }
            >
                <ListItemAvatar>
                    <Avatar>
                        <Folder/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                        primary={itemReceipt.item.name}
                        secondary={countItem(itemReceipt.count)}
                />
            </ListItem>);
};

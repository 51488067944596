import { PayloadAction } from "@reduxjs/toolkit";
import { ServerState } from "@common/types/common";

export const loadingFetching = (state: ServerState) => {
    state.error = '';
    state.isLoading = true;
};
export const loadingFetchingSuccess = (state: ServerState) => {
    state.isLoading = false;
    state.error = '';
};
export const loadingFetchingError = (state: ServerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.error = action.payload;
};

export const commonReducer = {
    loadingFetching,
    loadingFetchingError,
    loadingFetchingSuccess
}

import React, { FC, PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useAppDispatch } from "@common/hooks/redux";
import { setCurrentPage } from "@store/reducers/currentPageSlice";

interface LoadingProps extends PropsWithChildren {
    name: string,
    isLoading?: boolean,
    isEmpty?: boolean,
    error?: FetchBaseQueryError | SerializedError | undefined | string | null,
}

const LoadingLayout: FC<LoadingProps> = ({ isLoading, isEmpty, error, name, children }) => {

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setCurrentPage(name));
    }, []);
    return (<div>
                {/*<Typography variant="h3" color="text.secondary" sx={{ textAlign: 'center' }}>{name}</Typography>*/}
                {isLoading && <Typography variant="h3" color="text.secondary">Загрузка</Typography>}
                {error && <div>
                    <h3>Ошибка загрузки данных:</h3>
                    <pre>{JSON.stringify(error)}</pre>
                </div>}
                {isEmpty && <h5>Пусто</h5>}
                {children}
            </div>
    );
};

export default LoadingLayout;

import * as React from 'react';
import { closeSnackbar, SnackbarOrigin, SnackbarProvider } from 'notistack';
import { IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import "./notification-layout.styles.scss";
import theme from "@common/theme";

export const NotificationLayout = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const anchorOrigin: SnackbarOrigin = isMobile
            ? { vertical: 'top', horizontal: 'center' }
            : { vertical: 'bottom', horizontal: 'right' };
    const maxSnack = isMobile ? 1 : 5;
    return (
            <SnackbarProvider
                    maxSnack={maxSnack}
                    anchorOrigin={anchorOrigin}
                    action={(snackbarId) => (
                            <IconButton size="small" color="inherit" onClick={() => closeSnackbar(snackbarId)}>
                                <CloseIcon/>
                            </IconButton>

                    )}
            />
    );
}

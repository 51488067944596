import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '@store/reducers/authSlice';

const LogoutPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.removeItem('authToken');
        dispatch(logout());

        const timerId = setTimeout(() => {
            window.location.href = '/login';
        }, 2000);
        // Отменяем таймер при размонтировании компонента
        return () => clearTimeout(timerId);
    }, [dispatch]);

    return (
            <div>
                <p>Вы успешно разлогинились!</p>
                <p>Вы будете перенаправлены на страницу логина через 2 секунды...</p>
            </div>
    );
};

export default LogoutPage;

import { Middleware } from '@reduxjs/toolkit';
import { login, logout } from "@store/reducers/authSlice";
import { guestLinks } from "../router";

const authMiddleware: Middleware = ({ getState, dispatch }) => next => action => {
    const authState = getState().authReducer;

    const { pathname } = window.location;

    if (authState.isAuthenticated || action.type === login.type || guestLinks.includes(pathname)) {
        return next(action);
    }

    const token = localStorage.getItem('authToken');
    if (token) {
        dispatch(login(token));
        return next(action);
    }

    if (action.type !== logout.type) {
        dispatch(logout());
        window.location.href = '/login';
    }

    return next(action);
}

export default authMiddleware;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoginData {
    userName: string;
    password: string;
}

export interface LoginResponse {
    access_token: string;
}

interface AuthState {
    isAuthenticated: boolean;
    token: string | null;
}

const initialState: AuthState = {
    isAuthenticated: false,
    token: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action: PayloadAction<string>) {
            state.isAuthenticated = true;
            state.token = action.payload;
        },
        logout(state) {
            state.isAuthenticated = false;
            state.token = null;
        },
    },
});

export const { login, logout } = authSlice.actions;
export const authReducer = authSlice.reducer

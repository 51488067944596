import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { mainConfig } from "@common/config/main.config";
import { LoginData, LoginResponse } from "@store/reducers/authSlice";

const { baseUrl } = mainConfig;
export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginData>({
            query: (body: LoginData) => ({
                url: 'auth/login',
                method: 'POST',
                body: body
            })
        })
    }),
})

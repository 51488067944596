import { createApi } from "@reduxjs/toolkit/query/react";
import { IReceipt } from "@common/types/receipt";
import { SaveHelper } from "@common/helpers/save.helper";
import { baseQuery } from "@services/baseQuery.api";

const tagType = "Receipt";
export const receiptApi = createApi({
    reducerPath: "receiptApi",
    baseQuery,
    tagTypes: [tagType],
    endpoints: (builder) => ({
        getReceipts: builder.query<IReceipt[], void>({
            query: (limit?) => ({
                url: "receipts",
                params: { limit }
            }),
            providesTags: [tagType]
        }),
        getReceipt: builder.query<IReceipt, { receiptId: string }>({
            query: ({ receiptId }) => ({
                url: `receipts/${receiptId}`
            })
        }),
        createReceipt: builder.mutation<IReceipt, IReceipt>({
            query: (receipt: IReceipt) => ({
                url: "receipts",
                method: "POST",
                body: receipt
            }),
            invalidatesTags: [tagType]
        }),
        saveReceipt: builder.mutation<IReceipt, IReceipt>({
            query: (receipt: IReceipt) => SaveHelper.getQuery("receipts", receipt),
            invalidatesTags: [tagType]
        })
    })
});

import { createApi } from "@reduxjs/toolkit/query/react";
import { IItem } from "@common/types/item";
import { SaveHelper } from "@common/helpers/save.helper";
import { baseQuery } from "@services/baseQuery.api";

const tagType = 'Item';


export const itemApi = createApi({
    reducerPath: 'itemApi',
    baseQuery,
    tagTypes: [tagType],
    endpoints: (builder) => ({
        getItems: builder.query<IItem[], void>({
            query: (limit?) => ({
                url: 'items',
                params: { limit }
            }),
            providesTags: [tagType]
        }),
        saveItem: builder.mutation<IItem, Partial<IItem>>({
            query: (item: IItem) => SaveHelper.getQuery('items', item),
            invalidatesTags: [tagType]
        })
    }),
})

import React, { FC, useState } from "react";
import { Fab, InputAdornment, Stack, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import { ItemReceipt } from "@common/types/receipt";
import { IItem } from "@common/types/item";
import { CommonAutocompleteLayout } from "@components/layouts/item-autocomplete.layout";
import { itemApi } from "@services/item.api";
import { SaveHelper } from "@common/helpers/save.helper";

interface IAddItem {
    addItem: (item: ItemReceipt) => void;
}

export const AddItemReceipt: FC<IAddItem> = ({ addItem }) => {
    const [item, setItem] = useState<IItem | null>(null);
    const [count, setCount] = useState<string>("");

    const { data: items } = itemApi.useGetItemsQuery();
    const [saveItem] = itemApi.useSaveItemMutation();

    const handleSaveItem = async (item: IItem | null) => {
        if (item?.id === SaveHelper.addId) {
            item = await saveItem(item).unwrap();
            // console.log(item); // можно здесь обработать успешное сохранение
        }
        setItem(item);
    };
    const changeCount = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Todo change to react-number-format
        const { value } = e.target;

        if (value === "" || !Number.isNaN(parseFloat(value))) {
            setCount(value);
        }
    };
    const addNewItemList = () => {
        if (!item?.id) {
            return;
        }

        const countNumber = count === "" ? null : parseFloat(count);
        const itemReceipt: ItemReceipt = {
            itemId: item.id,
            item,
            count: countNumber,
        };
        addItem(itemReceipt);
        setCount("");
        // setItem({ id: 0, name: '' });
        setItem(null);
    };
    return (
            <>
                <Stack direction="row" spacing={2}>
                    <CommonAutocompleteLayout

                            label="Item"
                            entity={item}
                            entities={items || []}
                            onSelectItem={handleSaveItem}
                    />
                    <TextField
                            value={count}
                            onChange={changeCount}
                            label="Count"
                            sx={{ m: 1, width: "25ch" }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                            }}
                    />
                    <Fab onClick={addNewItemList} color="primary" aria-label="add">
                        <Add/>
                    </Fab>
                </Stack>
                <p>{JSON.stringify(item)}</p>
            </>
    );
};

import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "@store/store";
import { mainConfig } from "@common/config/main.config";
import { handleApiError } from "@services/helpers/errorHandler";


const { baseUrl } = mainConfig;
export const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState;
        const token = state.authReducer.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    fetchFn: async (input, init) => {
        const response = await fetch(input, init);
        if (!response.ok) {
            const message = await response.text();
            handleApiError(response.status, message || "Server error");
        }
        return response
    },
});

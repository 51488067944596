import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReceipt, ReceiptState } from "@common/types/receipt";
import { commonReducer, loadingFetchingSuccess } from "@store/reducers/common.reducers";

const initialState: ReceiptState = {
    receipts: [],
    isLoading: false,
    error: null
}

export const receiptSlice = createSlice({
    name: 'receipt',
    initialState,
    reducers: {
        ...commonReducer,
        loadingFetchingSuccess: (state: ReceiptState, action: PayloadAction<IReceipt[]>) => {
            loadingFetchingSuccess(state)
            state.receipts = action.payload;
        },
        createReceipt: (state: ReceiptState, action: PayloadAction<IReceipt>) => {
            loadingFetchingSuccess(state)
            state.receipts.push(action.payload);
        },
    }
})

export const receiptReducer = receiptSlice.reducer

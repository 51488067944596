import { createApi } from "@reduxjs/toolkit/query/react";
import { IAddListMenu, ICreateList, IItemListChangeStatus, IList, ListType } from "@common/types/list";
import { IChangeStatus } from "@common/types/menu";
import { baseQuery } from "@services/baseQuery.api";

const tagType = 'List';
export const listApi = createApi({
    reducerPath: 'listApi',
    baseQuery,
    tagTypes: [tagType],
    endpoints: (builder) => ({
        getCurrentList: builder.query<IList, void>({
            query: () => ({
                url: 'lists',
                params: { limit: 1, type: ListType.NEXT }
            }),
            transformResponse: ([rawResult]: IList[]): IList => {
                return rawResult || null
            },
            providesTags: [tagType]
        }),
        getLists: builder.query<IList[], void>({
            query: (limit?) => ({
                url: 'lists',
                params: { limit }
            }),
            providesTags: [tagType]
        }),
        changeStatus: builder.mutation<IList, IChangeStatus>({
            query: ({ id, status }) => ({
                url: `lists/${id}/changeStatus`,
                method: 'PATCH',
                body: { status }
            }),
            invalidatesTags: [tagType]
        }),
        changeItemStatus: builder.mutation<void, IItemListChangeStatus>({
            query: ({ id, status }) => ({
                url: `items-list/${id}/changeStatus`,
                method: 'PATCH',
                body: { status }
            }),
            // invalidatesTags: [tagType]
        }),
        addMenu: builder.mutation<void, IAddListMenu>({
            query: ({ listId, menuIds }) => ({
                url: `lists/${listId}/add-menu`,
                method: 'PATCH',
                body: { menuIds }
            }),
            invalidatesTags: [tagType]
        }),
        createList: builder.mutation<IList, ICreateList>({
            query: (list: ICreateList) => ({
                url: `lists`,
                method: 'POST',
                body: list
            }),
            invalidatesTags: [tagType]
        }),
    }),
})

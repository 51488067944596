import React from 'react';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const StyledFab = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
}));

interface Props {
    onClick: () => void;
}

export const AddButton: React.FC<Props> = ({ onClick }) => {
    return (
            <StyledFab color="primary" onClick={onClick}>
                <AddIcon/>
            </StyledFab>
    );
};

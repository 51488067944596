import React, { FC, PropsWithChildren } from "react";
import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
// import styles from "@styles/item.list.module.scss";
import '@styles/item.list.scss';
import { IItem } from "@common/types/item";
import { Delete, Edit } from "@mui/icons-material";

interface ItemListProps extends PropsWithChildren {
    item: IItem
    editAction?: (item: IItem) => void
}

export const Item: FC<ItemListProps> = ({ item, editAction }) => {
    const { id, name } = item
    const editItem = () => {
        if (editAction) {
            editAction(item)
        }
    }
    return (<>
                <ListItem
                        key={id}
                        // secondaryAction={}
                        disablePadding
                >
                    <ListItemButton onClick={editItem} dense>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className="cardBody" primary={name}/>
                    </ListItemButton>
                    <Stack direction="row" spacing={2}>
                        <IconButton onClick={editItem} edge="end" aria-label="edit">
                            <Edit/>
                        </IconButton>
                        <IconButton edge="end" aria-label="delete">
                            <Delete/>
                        </IconButton>
                    </Stack>
                </ListItem>
            </>
    );
};

import React, { FC, PropsWithChildren } from "react";
import { Avatar, Button, Card, CardActions, CardContent, Grid, Tooltip, Typography, } from "@mui/material";
import { IMenu, CommonStatus } from "@common/types/menu";
import { stringToColor } from "@common/hooks/color";
import { menuApi } from "@services/menu.api";
import { listApi } from "@services/list.api";
import { ListType } from "@common/types/list";

interface ItemReceiptProps extends PropsWithChildren {
    hidden?: boolean;
    title?: string;
    menu?: IMenu;
}

export const ItemMenu: FC<ItemReceiptProps> = ({
                                                   menu,
                                                   title,
                                                   hidden = false,
                                               }) => {
    const [changeStatus] = menuApi.useChangeStatusMutation();
    const { data: currentList } = listApi.useGetCurrentListQuery();
    const [addListMenu] = listApi.useAddMenuMutation();
    const [createList] = listApi.useCreateListMutation();

    const setDoneStatus = async () => {
        if (!menu || !menu.id) {
            return;
        }
        return changeStatus({ id: menu.id, status: CommonStatus.DONE });
    };

    const addMenuToList = (listId?: string) => async () => {
        if (!menu || !menu.id) {
            return;
        }
        const menuIds = [menu.id];
        if (!listId) {
            return createList({ type: ListType.NEXT, menuIds }).unwrap();
        }
        return addListMenu({ listId, menuIds });
    };
    return (
            <div hidden={hidden || false}>
                {/*{title && (*/}
                {/*        <Typography variant="h5" textAlign="center" component="div">*/}
                {/*            {title}*/}
                {/*        </Typography>*/}
                {/*)}*/}
                <Card variant="outlined">
                    <CardContent sx={{ height: 200 }}>
                        {!title && (
                                <Typography variant="h5" textAlign="center" component="div">
                                    {menu?.name}
                                </Typography>
                        )}
                        {menu?.receipts.map((receipt, index) => (
                                <Grid pb={1} key={index} container spacing={1}>
                                    <Grid item xs="auto">
                                        <Tooltip
                                                title={receipt.description}
                                                placement="left-start"
                                                arrow
                                        >
                                            <Avatar
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                        bgcolor: stringToColor(receipt.name),
                                                    }}
                                            >
                                                {receipt.name[0]}
                                            </Avatar>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography color="text.primary">{receipt.name}</Typography>
                                    </Grid>
                                </Grid>
                        ))}
                        {!menu && (
                                <Typography
                                        textAlign="center"
                                        variant="body2"
                                        color="text.secondary"
                                >
                                    Empty
                                </Typography>
                        )}
                    </CardContent>
                    <CardActions>
                        {menu && (
                                <>
                                    <Button onClick={setDoneStatus} size="small">
                                        Done
                                    </Button>
                                    <Button onClick={addMenuToList(currentList?.id)} size="small">
                                        {" "}
                                        Add to current list
                                    </Button>
                                </>
                        )}
                        {!menu && <Button size="small">Add to this menu</Button>}
                    </CardActions>
                </Card>
            </div>
    );
};

import React from 'react';
import MainLayout from "@components/layouts/main.layout";
import LoadingLayout from "@components/layouts/loading.layout";
import { pageNames, paths } from "../router";
import { receiptApi } from "@services/receipt.api";
import { ItemReceipt } from "@components/receipt/item.receipt";
import { Masonry } from '@mui/lab';
import { AddButton } from "@components/layouts/button/add-button.layout";
import { useNavigate } from "react-router-dom";

export const Receipts: React.FC = () => {
    const pageName = pageNames.receipts
    const { data, error, isLoading } = receiptApi.useGetReceiptsQuery();
    const navigate = useNavigate();

    const createReceipt = () => {
        navigate(paths.createReceipt)
    }
    return (
            <MainLayout>
                <AddButton onClick={createReceipt}/>
                <LoadingLayout name={pageName} isLoading={isLoading} error={error} isEmpty={data?.length === 0}>
                    {data && <Masonry

                        defaultHeight={450}
                        defaultColumns={4}
                        defaultSpacing={1}
                        columns={{ xs: 1, sm: 2, md: 3 }}
                        spacing={1}>
                        {data.map((receipt) => (
                                <ItemReceipt key={receipt.id} receipt={receipt}/>
                        ))}
                    </Masonry>}
                </LoadingLayout>
            </MainLayout>
    );
};

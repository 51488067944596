import React, { FC } from "react";
import { Chip, Divider } from "@mui/material";
import { getTextColor } from "@common/hooks/color";
import { ICategory } from "@common/types/item";

interface IGroupByCategoryProps {
  category: ICategory;
}

export const getColor = (category: ICategory): string => {
  return category.color || "#000";
};

export const GroupByCategory: FC<IGroupByCategoryProps> = ({ category }) => {
  return (<>
      {/*<Typography*/}
      {/*  sx={{ mt: 0.5, ml: 2 }}*/}
      {/*  color="text.secondary"*/}
      {/*  display="block"*/}
      {/*  variant="caption"*/}
      {/*>*/}
      {/*  {category.name}*/}
      {/*</Typography>*/}
      <Divider>
        <Chip
          label={category.name}
          sx={{
            color: getTextColor(getColor(category)),
            backgroundColor: getColor(category)
          }}
        />
      </Divider>
      {/*<Divider variant="inset" sx={{ backgroundColor: getColor(category) }}/>*/}
    </>
  );
};


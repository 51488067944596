import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from "react-redux";
import { store } from '@store/store';
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "@common/theme";

const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
);
root.render(
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <App/>
            </ThemeProvider>,
        </Provider>
);


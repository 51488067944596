import React, { useState } from "react";
import isEmail from "validator/lib/isEmail";
import { Button, CircularProgress, TextField } from "@mui/material";
import { userApi } from "@services/user.api";
import { IUserCreate } from "@common/types/user";
import "@styles/create.user.scss";

interface IUserValidationError {
  emailError?: string;
  passwordError?: string;
  passwordConfirmError?: string;
}

const userDefault: IUserCreate = {
  name: "",
  userName: "",
  password: "",
  passwordConfirm: "",
};
export const CreateUser = () => {
  const [user, setUser] = useState<IUserCreate>(userDefault);
  const [errors, setErrors] = useState<IUserValidationError>({});

  const [createUser, { isLoading, error, data }] =
    userApi.useCreateUserMutation();

  const handleUserChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    setErrors((prevErrors) => ({ ...prevErrors, [`${name}Error`]: undefined }));
    if (e.target.name === "email")
      setErrors({ ...errors, emailError: undefined });
    if (e.target.name === "password")
      setErrors({ ...errors, passwordError: undefined });
    if (e.target.name === "passwordConfirm")
      setErrors({ ...errors, passwordConfirmError: undefined });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let isValid = true;
    if (user.email && !isEmail(user.email)) {
      setErrors({
        ...errors,
        emailError: "Please enter a valid email address",
      });
      isValid = false;
    }
    if (user.password.length < 4) {
      setErrors({
        ...errors,
        passwordError: "Password must be at least 4 characters long",
      });
      isValid = false;
    }
    if (user.password !== user.passwordConfirm) {
      setErrors({ ...errors, passwordConfirmError: "Passwords do not match" });
      isValid = false;
    }
    if (isValid) {
      try {
          await createUser(user).unwrap();
          setUser(userDefault);
          setErrors({});
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className="root">
      <h2>Create User</h2>
      <form className="form" onSubmit={handleSubmit}>
        <TextField
          margin="dense"
          label="Name"
          name="name"
          value={user.name}
          onChange={handleUserChange}
        />
        <TextField
          margin="dense"
          label="User name"
          name="userName"
          value={user.userName}
          onChange={handleUserChange}
          required
        />
        <TextField
          margin="dense"
          label="Email"
          name="email"
          value={user.email}
          onChange={handleUserChange}
          type="email"
          error={!!errors.emailError}
          helperText={errors.emailError}
        />
        <TextField
          margin="dense"
          label="Password"
          name="password"
          value={user.password}
          onChange={handleUserChange}
          type="password"
          required
          error={!!errors.passwordError}
          helperText={errors.passwordError}
        />
        <TextField
          margin="dense"
          label="Confirm Password"
          name="passwordConfirm"
          value={user.passwordConfirm}
          onChange={handleUserChange}
          type="password"
          required
          error={!!errors.passwordConfirmError}
          helperText={errors.passwordConfirmError}
        />
        {isLoading ? (
          <CircularProgress className="progress" />
        ) : (
          <Button
            className="button"
            variant="contained"
            color="primary"
            type="submit"
          >
            Create
          </Button>
        )}
      </form>
      {error && <p>Error creating user :(</p>}
      {data && <p>User created successfully!</p>}
    </div>
  );
};

export default CreateUser;

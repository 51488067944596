import React from 'react';
import MainLayout from "@components/layouts/main.layout";
import LoadingLayout from "@components/layouts/loading.layout";
import { pageNames } from "../router";
import { userApi } from "@services/user.api";
import CreateUser from "@components/users/create.user";

export const Users: React.FC = () => {
    const pageName = pageNames.users
    const { data: users, error, isLoading } = userApi.useGetUsersQuery(10);
    // const { users, isLoading, error } = useAppSelector(state => state.userReducer)
    // const dispatch = useAppDispatch();
    //
    // useEffect(() => {
    //     dispatch(fetchUsers())
    // }, [])

    return (
            <MainLayout>
                <CreateUser/>
                <LoadingLayout name={pageName} isLoading={isLoading} error={error} isEmpty={users?.length === 0}>
                    {users && users.map((user) => <div key={user.id}>{user.name}</div>)}
                </LoadingLayout>
            </MainLayout>
    );
};

import { ICommonEntity } from "@common/types/common";

export class SaveHelper {
    static addId = 'add'

    static getMethod(id: string) {
        return id === this.addId ? 'POST' : 'PUT'
    }

    static getUrl(url: string, id: string) {
        return id === this.addId ? url : `${url}/${id}`
    }

    static getQuery(url: string, entity: ICommonEntity) {
        return {
            url: SaveHelper.getUrl(url, entity.id),
            method: SaveHelper.getMethod(entity.id),
            body: entity
        }
    }
}

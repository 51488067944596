import {Receipts, Users} from "@pages";
import {createBrowserRouter} from "react-router-dom";
import {ErrorBoundary} from "@pages/error";
import {CreateReceipt} from "@pages/create.receipt";
import {Menu} from "@pages/menu";
import {Lists} from "@pages/lists";
import {Items} from "@pages/items";
import LoginPage from "@pages/login.page";
import LogoutPage from "@pages/logout.page";
import * as React from "react";
import {ReactNode} from "react";

import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

export interface PageLink {
    name: string,
    path: string,
    icon?: ReactNode;
    hide?: boolean
}

export const paths = {
    home: "/",
    menu: "/menu",
    items: "/items",
    lists: "/lists",
    receipts: "/receipts",
    createReceipt: "/receipts/new",
    users: "/users",
    login: "/login",
    logout: "/logout"
};
export const pageNames = {
    home: "Home",
    menu: "Menu",
    items: "Items",
    lists: "Lists",
    receipts: "Receipts",
    users: "Users",
    createReceipt: "Create Receipt",
    login: "Login",
    logout: "Logout"
};
export const pageIcons = {
    home: <HomeIcon/>,
    menu: <MenuIcon/>,
    items: <LocalMallIcon/>,
    lists: <FormatListBulletedIcon/>,
    receipts: <ReceiptIcon/>,
    users: <PersonIcon/>,
    createReceipt: <AddCircleIcon/>,
    login: <LoginIcon/>,
    logout: <LogoutIcon/>,
}

export const pages: PageLink[] = [
    {name: pageNames.home, path: paths.home, icon: pageIcons.home},
    {name: pageNames.lists, path: paths.lists, icon: pageIcons.lists},
    {name: pageNames.items, path: paths.items, icon: pageIcons.items},
    {name: pageNames.receipts, path: paths.receipts, icon: pageIcons.receipts},
    {name: pageNames.createReceipt, path: paths.createReceipt, icon: pageIcons.createReceipt},
    {name: pageNames.users, path: paths.users, icon: pageIcons.users}
];

export const guestLinks = [paths.login];
export const settingLink = [
    {name: pageNames.logout, path: paths.logout, icon: pageIcons.logout}
];

export const bottomPages = [
    {name: pageNames.home, path: paths.home, icon: pageIcons.home},
    {name: pageNames.lists, path: paths.lists, icon: pageIcons.lists},
    {name: pageNames.receipts, path: paths.receipts, icon: pageIcons.receipts},
];
// export const bottomPages = [
//     { name: pageNames.menu, icon: <RestoreIcon/>, path: paths.menu },
//     { name: pageNames.lists, icon: <FavoriteIcon/>, path: paths.lists },
//     { name: pageNames.receipts, icon: <ArchiveIcon/>, path: paths.receipts },
// ];

export const privateRoutes = createBrowserRouter([
    {
        path: paths.home,
        element: <Menu/>,
        errorElement: <ErrorBoundary/>
    },
    {
        path: paths.menu,
        element: <Menu/>
    },
    {
        path: paths.items,
        element: <Items/>
    },
    {
        path: paths.lists,
        element: <Lists/>
    },
    {
        path: paths.receipts,
        element: <Receipts/>
    },
    {
        path: paths.receipts + "/:id",
        element: <CreateReceipt/>
    },
    {
        path: paths.users,
        element: <Users/>
    },
    {
        path: paths.login,
        element: <LoginPage/>
    },
    {
        path: paths.logout,
        element: <LogoutPage/>
    }
]);

import { createSlice } from '@reduxjs/toolkit';

const currentPageSlice = createSlice({
    name: 'currentPage',
    initialState: '',
    reducers: {
        setCurrentPage: (state, action) => {
            return action.payload;
        },
    },
});

export const { setCurrentPage } = currentPageSlice.actions;

export const currentPageReducer = currentPageSlice.reducer

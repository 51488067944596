import React, { FC, PropsWithChildren } from "react";
import { Avatar, Button, Card, CardActions, CardContent, Grid, IconButton, Tooltip, Typography, } from "@mui/material";
import { IReceipt } from "@common/types/receipt";
import { countItem } from "@components/receipt/list.item.receipt";
import { stringToColor } from "@common/hooks/color";
import { menuApi } from "@services/menu.api";
import { menuDefault, MenuType } from "@common/types/menu";
import { SaveHelper } from "@common/helpers/save.helper";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { paths } from "../../router";

interface ItemReceiptProps extends PropsWithChildren {
    receipt: IReceipt;
}

const addId = SaveHelper.addId;
export const ItemReceipt: FC<ItemReceiptProps> = ({ receipt }) => {
    const { id = addId, name, description } = receipt;

    const navigate = useNavigate();

    const { data } = menuApi.useGetMainMenuQuery();
    const [createMenu] = menuApi.useCreateMenuMutation();
    const [addReceiptsMenu] = menuApi.useAddReceiptsMenuMutation();
    const { current, next } = data || menuDefault;

    const addReceiptToMenu = (menuId?: string) => async () => {
        const receiptIds = [id];
        if (menuId) {
            return addReceiptsMenu({ menuId, receiptIds });
        }
        return createMenu({ type: MenuType.NEXT, receiptIds });
    };

    function goToEditReceipt() {
        return navigate(paths.receipts + '/' + id);
    }

    return (
            <Card variant="outlined">
                <CardContent>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h5" component="div">
                            {name}
                        </Typography>
                        <IconButton onClick={goToEditReceipt} edge="end" aria-label="edit">
                            <Edit/>
                        </IconButton>
                    </div>
                    <Typography color="text.secondary">
                        {description}
                    </Typography>

                    {receipt.items &&
                            receipt.items.map((itemReceipt, index) => (
                                    <Grid pb={1} key={index} container spacing={1}>
                                        <Grid item xs="auto">
                                            <Tooltip title="category" placement="left-start" arrow>
                                                <Avatar
                                                        sx={{
                                                            bgcolor: stringToColor(itemReceipt.item.name),
                                                            width: 24,
                                                            height: 24,
                                                        }}
                                                >
                                                    {itemReceipt.item.name[0]}
                                                </Avatar>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.primary">
                                                {itemReceipt.item.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            {countItem(itemReceipt.count)}
                                        </Grid>
                                    </Grid>
                            ))}
                    {/*<Typography variant="body2">{JSON.stringify(receipt)}</Typography>*/}
                </CardContent>
                <CardActions>
                    <Button onClick={addReceiptToMenu(current?.id)} size="small">
                        {" "}
                        Add to current
                    </Button>
                    <Button onClick={addReceiptToMenu(next[3]?.id)} size="small">
                        {" "}
                        Add to next
                    </Button>
                </CardActions>
            </Card>
    );
};

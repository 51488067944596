import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import React from "react";
import MainLayout from "@components/layouts/main.layout";

export const ErrorBoundary: React.FC = () => {
    const error = useRouteError();
    if (isRouteErrorResponse(error)) {
        return (
                <MainLayout>
                    <h1>Oops!</h1>
                    <h2>{error.status}</h2>
                    <p>{error.statusText}</p>
                    {error.data?.message && <p>{error.data.message}</p>}
                </MainLayout>
        );
    } else {
        return <MainLayout>Oops</MainLayout>;
    }
};

import * as React from 'react';
import {PropsWithChildren} from 'react';
import {Container} from "@mui/material";
import BottomLayout from "@components/layouts/bottom.layout";
import {NavbarLayout} from "@components/layouts/navbar.layout";
import {NotificationLayout} from "@components/layouts/notification/notification.layout";

const MainLayout: React.FC<PropsWithChildren> = ({children}) => {
    return (
        <>
            <NavbarLayout/>
            <NotificationLayout/>

            <Container sx={{ paddingTop: '56px' }}>
                {children}
            </Container>
            <BottomLayout/>
        </>
    );
};

export default MainLayout;

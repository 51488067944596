import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { List } from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { PageLink } from "../../../router";
import { Link } from "react-router-dom";

type ListItemsProps = {
    items: PageLink[];
}
export const ListNavbarLayout = ({ items }: ListItemsProps) => (
        <List>
            {items.map(({ path, name, icon }, index) => (
                    <ListItem key={path} disablePadding>
                        <ListItemButton component={Link} to={path}>
                            <ListItemIcon>
                                {icon ? icon : index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                            </ListItemIcon>
                            <ListItemText primary={name}/>
                        </ListItemButton>
                    </ListItem>
            ))}
        </List>
);

import React from "react";
import { pageNames } from "../router";
import MainLayout from "@components/layouts/main.layout";
import LoadingLayout from "@components/layouts/loading.layout";
import { FormReceipt } from "@components/receipt/form.receipt";

export const CreateReceipt = () => {
    const pageName = pageNames.createReceipt;
    return (
            <MainLayout>
                <LoadingLayout name={pageName}>
                    <FormReceipt/>
                </LoadingLayout>
            </MainLayout>
    );
};

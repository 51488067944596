import React, { FC, useEffect, useState } from "react";
import { Button, List, TextField } from "@mui/material";
import { IReceipt, ItemReceipt } from "@common/types/receipt";
import { receiptApi } from "@services/receipt.api";
import { Add as AddIcon } from "@mui/icons-material";
import { AddItemReceipt } from "@components/receipt/add.item.receipt";
import { ListItemReceipt } from "@components/receipt/list.item.receipt";
import { useNavigate, useParams } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { SaveHelper } from "@common/helpers/save.helper";
import { paths } from "../../router";

const defaultReceipt: IReceipt = {
    id: SaveHelper.addId,
    name: "",
    description: "",
    items: []
};
export const FormReceipt: FC = () => {
    const navigate = useNavigate();
    const { id: receiptId } = useParams();

    const [receipt, setReceipt] = useState<IReceipt>(defaultReceipt);
    const { data } = (receiptId && receiptId !== "new") ? receiptApi.useGetReceiptQuery({ receiptId }) : { data: defaultReceipt };
    useEffect(() => {
        if (data) {
            setReceipt(data);
        }
    }, [data]);

    const isCreate = receipt.id === SaveHelper.addId;

    const [saveReceipt, { error, isLoading }] = receiptApi.useSaveReceiptMutation();

    const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReceipt({ ...receipt, name: e.target.value });
    };
    const changeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReceipt({ ...receipt, description: e.target.value });
    };
    const addItem = (item: ItemReceipt): void => {
        setReceipt({ ...receipt, items: [...receipt.items, item] });
    };
    const saveButton = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const updated = await saveReceipt(receipt).unwrap();

        console.log('updated', updated);
        navigate(paths.receipts);
    };
    const handleDeleteItem = (key: number) => {
        const updatedItems = receipt.items.filter((item, index) => index !== key);
        setReceipt({ ...receipt, items: updatedItems });
    };

    return (
            <>
                {error && <h5>{JSON.stringify(error)}</h5>}
                {isLoading && <h3>Создание...</h3>}
                {/*<p>{JSON.stringify(receipt)}</p>*/}
                <TextField
                        label="Name"
                        margin="dense"
                        fullWidth
                        onChange={changeName}
                        value={receipt.name}
                />
                <TextField
                        label="Description"
                        margin="dense"
                        fullWidth
                        multiline
                        minRows={4}
                        onChange={changeDescription}
                        value={receipt.description}
                />

                <List dense>
                    {receipt.items &&
                            receipt.items.map((item, index) => (
                                    <ListItemReceipt onDelete={() => {handleDeleteItem(index)}} key={index} itemReceipt={item}/>
                            ))}
                </List>
                <AddItemReceipt addItem={addItem}/>
                {/*<Fab onClick={saveButton}  color="primary" aria-label="add">*/}
                {/*    <AddIcon />*/}
                {/*</Fab>*/}
                {isCreate &&
                    <Button onClick={saveButton} variant="contained" color="success"><AddIcon
                        sx={{ mr: 1 }}/>Create</Button>
                }
                {!isCreate &&
                    <Button onClick={saveButton} variant="contained" color="info"><DoneIcon
                        sx={{ mr: 1 }}/>Save</Button>
                }
            </>
    );
};


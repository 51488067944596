import * as React from "react";
import { FC, SyntheticEvent } from "react";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { SaveHelper } from "@common/helpers/save.helper";
import { ICommonNameEntity } from "@common/types/common";
import { FilterOptionsState } from "@mui/base/AutocompleteUnstyled/useAutocomplete";

const filter = createFilterOptions<ICommonNameEntity>();
const addId = SaveHelper.addId;

interface ISelectAndAddProps {
  label: string;
  entity: ICommonNameEntity | null;
  entities: ICommonNameEntity[];
  onSelectItem: (item: ICommonNameEntity | null) => Promise<void>;
}

const createNewItem = (name: string): ICommonNameEntity => {
  return { id: addId, name };
};

export const CommonAutocompleteLayout: FC<ISelectAndAddProps> = ({
  label,
  entity,
  onSelectItem,
  entities,
}: ISelectAndAddProps) => {
  let inputItemName: string;

  const selectNewItem = (
    newValue: ICommonNameEntity | string | null
  ): ICommonNameEntity | null => {
    if (typeof newValue === "string") {
      return createNewItem(newValue);
    }
    if (newValue?.id === addId) {
      return createNewItem(inputItemName);
    }
    return newValue;
  };
  const handleSelect = async (
    event: SyntheticEvent,
    newValue: ICommonNameEntity | string | null
  ) => {
    const selected = selectNewItem(newValue);
    await onSelectItem(selected);
  };

  const filterOptions = (
    options: ICommonNameEntity[],
    params: FilterOptionsState<ICommonNameEntity>
  ) => {
    const filtered = filter(options, params);
    const { inputValue } = params;

    const isExisting = options.some((option) => inputValue === option.name);
    if (inputValue !== "" && !isExisting) {
      inputItemName = inputValue;
      filtered.push(createNewItem(`Add "${inputValue}"`));
    }

    return filtered;
  };

  return (
    <Autocomplete
      value={entity}
      onChange={handleSelect}
      filterOptions={filterOptions}
      selectOnFocus
      handleHomeEndKeys
      clearOnBlur={true}
      options={entities}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option?.id === addId) {
          return inputItemName || "";
        }

        return option.name;
      }}
      renderOption={(props, option: ICommonNameEntity) => (
        <li {...props}>{option.name}</li>
      )}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { ListNavbarLayout } from "@components/layouts/navbar/list-navbar.layout";
import { pageIcons, pageNames, pages, paths, settingLink } from "../../../router";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppSelector } from "@common/hooks/redux";
import { AppBar, Container } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";

export function MobileNavbarLayout() {
    const [open, setOpen] = React.useState(false);

    const currentPageName = useAppSelector((state) => state.currentPageReducer)
    const isLoggedIn = useSelector((state: RootState) => state.authReducer.isAuthenticated);
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setOpen(open);
    };

    return (
            <Box sx={{ display: 'flex', mb: 2 }}>
                <AppBar position="fixed">

                    <Container>
                        <Toolbar>
                            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
                                {currentPageName}
                            </Typography>
                            <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={toggleDrawer(true)}
                                    sx={{ ...(open && { display: 'none' }) }}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Drawer
                        anchor='right'
                        open={open}
                        onClose={toggleDrawer(false)}
                >
                    <Box
                            sx={{ width: 250 }}
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                    >
                        {isLoggedIn && <ListNavbarLayout items={pages}/>}
                        <Divider/>

                        {isLoggedIn ?
                                (<ListNavbarLayout items={settingLink}/>)
                                : (<ListNavbarLayout
                                        items={[{ name: pageNames.login, path: paths.login, icon: pageIcons.login }]}
                                />)
                        }
                    </Box>
                </Drawer>
            </Box>
    );
}

import React, { useState } from 'react';
import MainLayout from "@components/layouts/main.layout";
import LoadingLayout from "@components/layouts/loading.layout";
import { pageNames } from "../router";
import { menuApi } from "@services/menu.api";
import { Grid, Tab, Tabs } from "@mui/material";
import { ItemMenu } from "@components/menu/item.menu";
import { menuDefault } from "@common/types/menu";

export const Menu: React.FC = () => {
    const pageName = pageNames.menu

    const { data, error, isLoading } = menuApi.useGetMainMenuQuery();
    const [nextTab, setNextTab] = useState<number>(0);

    const { current, near, next, customs } = data || menuDefault

    const tabChange = (event: React.SyntheticEvent, newValue: number) => {
        setNextTab(newValue);
    };
    const nextTitle = (index: number) => {
        if (index === 0) {
            return "Next"
        }
        return "Next " + (index + 1);
    }
    return (
            <MainLayout>
                <LoadingLayout name={pageName} isLoading={isLoading} error={error}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Tabs value={0}>
                                <Tab label='Current'/>
                            </Tabs>
                            <ItemMenu menu={current} title=""/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Tabs value={nextTab}
                                  onChange={tabChange}
                                  variant="scrollable"
                                  scrollButtons="auto">
                                {next.map((menuItem, index) => (
                                        <Tab key={index} label={nextTitle(index)}/>
                                ))}
                            </Tabs>
                            {next.map((menuItem, index) => (
                                    <ItemMenu hidden={nextTab !== index} key={menuItem.id} title="" menu={menuItem}/>
                            ))}


                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Tabs value={0}>
                                <Tab label={near?.name || "Near"}/>
                            </Tabs>
                            <ItemMenu menu={near} title={near?.name || "Near"}/>
                        </Grid>
                    </Grid>
                    {<Grid container spacing={2}>
                        {customs.map((menuItem) => (
                                <Grid key={menuItem.id} item xs={4}>
                                    <ItemMenu menu={menuItem}/>
                                </Grid>
                        ))}
                    </Grid>}
                </LoadingLayout>
            </MainLayout>
    );
};

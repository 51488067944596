import React, {useState} from "react";
import MainLayout from "@components/layouts/main.layout";
import LoadingLayout from "@components/layouts/loading.layout";
import {pageNames} from "../router";
import {Card, CardContent, List} from "@mui/material";
import {itemApi} from "@services/item.api";
import {CreateItem} from "@components/item/createItem";
import {ICategory, IItem, IProductsByCategory} from "@common/types/item";
import {Item} from "@components/item/Item";
import "@styles/items.scss";
import {stringToColor} from "@common/hooks/color";
import {getColor, GroupByCategory,} from "@components/layouts/groupByCategory.layout";
import {IItemList} from "@common/types/list";

const defaultCategory: ICategory = {
    id: "",
    name: "Uncategorized",
    color: "#B0B0B2",
};

export interface IListProductsObject {
    [key: string]: IProductsByCategory;
}

function compareUsersByAge(
    a: IProductsByCategory,
    b: IProductsByCategory,
    isDescending = true
): number {
    const result = a.category.name.localeCompare(b.category.name);
    return isDescending ? result : result * -1;
}

export const getTmpCategory = (tmpCategory: string): ICategory => {
    return {id: tmpCategory, name: tmpCategory, color: stringToColor(tmpCategory)};
}
export const getCategory = (product: IItem, itemList?: IItemList, itemsCats?: Map<string, string>): ICategory => {
    const {categoryId, category} = product;

    const itemName = product.name.toLowerCase();
    if (itemList?.tmpCategory) {
        return getTmpCategory(itemList.tmpCategory);
    }
    if (!categoryId || !category) {
        const tmpCategory = itemsCats && itemsCats.get(itemName);
        if(tmpCategory){
            return getTmpCategory(tmpCategory)
        }
        return defaultCategory;
    }
    if (!category.color) {
        category.color = stringToColor(category.name);
    }

    return category;
};
export const Items: React.FC = () => {
    const pageName = pageNames.items;
    const [editItem, setEditItem] = useState<IItem | null>();
    const {data, error, isLoading} = itemApi.useGetItemsQuery();

    const groupProductsByCategory = (
        products: IItem[]
    ): IProductsByCategory[] => {
        const productsByCategory: IListProductsObject = products.reduce(
            (result: IListProductsObject, product) => {
                const category = getCategory(product);
                const {id} = category;
                if (!result[id]) {
                    result[id] = {
                        category: category,
                        products: [],
                    };
                }
                result[id].products.push(product);
                return result;
            },
            {}
        );
        return Object.values(productsByCategory).sort((a, b) =>
            compareUsersByAge(a, b)
        );
    };

    return (
        <MainLayout>
            <CreateItem
                editItem={editItem}
                cancelAction={() => {
                    setEditItem(null);
                }}
            />
            <LoadingLayout name={pageName} isLoading={isLoading} error={error}>
                <Card variant="outlined">
                    <CardContent className="productsList">
                        <List>
                            {data &&
                                groupProductsByCategory(data).map(
                                    ({category, products}, index) => (
                                        <div key={index}>
                                            <GroupByCategory category={category}/>
                                            {products &&
                                                products.map((item, index2) => (
                                                    <div
                                                        key={index2}
                                                        className="products"
                                                        style={{borderColor: getColor(category)}}
                                                    >
                                                        <Item
                                                            editAction={(current) => {
                                                                setEditItem(current);
                                                            }}
                                                            item={item}
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    )
                                )}
                        </List>
                    </CardContent>
                </Card>
            </LoadingLayout>
        </MainLayout>
    );
};

import { IReceipt } from "@common/types/receipt";

export enum CommonStatus {
    ACTIVE = 'active',
    DELETED = 'deleted',
    DONE = 'done',
}

export enum MenuType {
    NEXT = 'next',
    CUSTOM = 'custom',
}

export interface IMainListMenu {
    current?: IMenu,
    near?: IMenu,
    next: IMenu[],
    customs: IMenu[]
}

export interface ICreateMenu {
    name?: string
    description?: string
    status?: CommonStatus
    type?: MenuType
    receiptIds: string[]
}

export interface IMenu {
    id?: string,
    name: string
    description?: string
    status: CommonStatus
    type: MenuType
    receipts: IReceipt[]
}

export interface IAddReceiptMenu {
    menuId: string,
    receiptIds: string[]
}
export interface IChangeStatus {
    id: string,
    status: CommonStatus
}


export const menuDefault: IMainListMenu = { next: [], customs: [] }

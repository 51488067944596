import React from "react";
import MainLayout from "@components/layouts/main.layout";
import LoadingLayout from "@components/layouts/loading.layout";
import {pageNames} from "../router";
import {listApi} from "@services/list.api";
import {Box, Button, List, Tab, Tabs} from "@mui/material";
import {ICategory} from "@common/types/item";
import {getCategory} from "@pages/items";
import {IItemList, IList, ListType} from "@common/types/list";
import {enqueueSnackbar} from "notistack";
import {CommonStatus} from "@common/types/menu";
import FlipMove from "react-flip-move";
import {GroupByCategory} from "@components/layouts/groupByCategory.layout";
import {ItemList} from "@components/list/item.list";
import {Add as AddIcon} from "@mui/icons-material";

export interface IListByCategory {
    category: ICategory;
    hasIncompleteItems: boolean;
    itemLists: {
        [key: string]: IItemList[];
    };
}

export interface IListObject {
    [key: string]: IListByCategory;
}

const customEnterAnimation = {
    from: {transform: 'scale(0.5, 1)'},
    to: {transform: 'scale(1, 1)'}
};
const getListName = (list: IList) => {
    if (list.name) {
        return list.name
    }
    if (list.type === 'next') {
        return 'Текущий'
    }

}

function getGroupedItems(itemLists: IItemList[]): IListByCategory[] {
    const groupedItems:any = {}
    // const groupedItems = new Map<string, IListByCategory>();
    const itemsCats = new Map<string, string>();

    for (const itemList of itemLists) {
        const {tmpCategory} = itemList;
        if (tmpCategory) {
            const itemName = itemList.item.name.toLowerCase();
            itemsCats.set(itemName, tmpCategory);
        }
    }

    for (const itemList of itemLists) {
        const {item, status} = itemList;
        const category = getCategory(item, itemList,itemsCats);
        const key = category.id;
        if (!groupedItems[key]) {
            groupedItems[key] = {
                category,
                itemLists: {},
                hasIncompleteItems: false,
            };
        }

        if (!groupedItems[key].itemLists[item.id]) {
            groupedItems[key].itemLists[item.id] = [];
        }

        groupedItems[key].itemLists[item.id].push(itemList);

        // If the item is not done, set hasIncompleteItems to true
        if (status !== CommonStatus.DONE) {
            groupedItems[key].hasIncompleteItems = true;
        }
    }
    // console.log(groupedItems,'groupedItems');
    return groupedItems;
}

const groupListByCategory = (itemLists: IItemList[]): IListByCategory[] => {


    const groupList = getGroupedItems(itemLists);

    // Convert the object to an array and sort it
    const groupListArray: IListByCategory[] = Object.values(groupList);
    groupListArray.sort((a, b) => {
        if (a.hasIncompleteItems && !b.hasIncompleteItems) {
            return -1;
        }
        if (!a.hasIncompleteItems && b.hasIncompleteItems) {
            return 1;
        }
        return 0;
    });

    return groupListArray;
};

export const Lists: React.FC = () => {
    const pageName = pageNames.lists;
    const [createList] = listApi.useCreateListMutation();
    const [changeStatus] = listApi.useChangeStatusMutation();
    const {data, error, isLoading} = listApi.useGetListsQuery();
    // const [current] = data || [];
    // const {id, items, name, description} = current || {};

    const [itemsState, setItemsState] = React.useState<IItemList[]>([]);
    const [listTab, setListTab] = React.useState<number>(0);


    const changeListTab = (event: React.SyntheticEvent, newValue: number) => {
        if (data) {
            setItemsState(data[newValue]?.items || []);
        }
        setListTab(newValue);
    };
    React.useEffect(() => {
        if (data) {
            setItemsState(data[listTab]?.items || []);
        }
    }, [data]);

    const setDoneStatus = async () => {
        try {
            const {id} = data?.[listTab] || {};
            if (id === undefined) return;
            await changeStatus({id, status: CommonStatus.DONE}).unwrap();
            enqueueSnackbar('Success to change status!', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Failed to change status!', {variant: 'warning'});
            console.warn(error)
        }
    };
    const createNewList = async () => {
        try {

            return createList({type: ListType.NEXT}).unwrap();
            enqueueSnackbar('Success to create list!', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Failed to create list!', {variant: 'warning'});
            console.warn(error)
        }
    }

    const changedItemStatus = async (ids: string[], status: CommonStatus) => {
        const updatedItems = itemsState.map(itemList => {
            if (ids.includes(itemList.id)) {
                return {...itemList, status};
            }
            return itemList;
        });
        setItemsState(updatedItems);
    };
    return (
        <MainLayout>
            <LoadingLayout name={pageName} isLoading={isLoading} error={error}>
                {(
                    <Box sx={{position: 'relative'}}>
                        <Tabs
                            value={listTab}
                            onChange={changeListTab}
                            variant={data && data?.length > 9 ? "scrollable" : "standard"}
                            scrollButtons
                            allowScrollButtonsMobile
                            centered
                        >
                            {data?.map((list, index) => (
                                    <Tab key={list.id} label={getListName(list)} value={index}/>
                                )
                            )}
                        </Tabs>
                        <List sx={{pb: 2}}>
                            <FlipMove typeName={null}>
                                {groupListByCategory(itemsState).map(
                                    ({category, itemLists}, index) => (
                                        <div key={category.id}>
                                            <GroupByCategory category={category}/>
                                            {Object.values(itemLists).map((itemList, index) => (
                                                <ItemList key={index} itemLists={itemList}
                                                          changedStatus={changedItemStatus}/>
                                            ))}
                                        </div>
                                    )
                                )}
                            </FlipMove>
                        </List>
                        {data?.[listTab] ? (

                            <Button onClick={setDoneStatus} variant="contained" color="primary" fullWidth>
                                Done
                            </Button>

                        ) : (
                            <Button onClick={createNewList} variant="contained" color="success" fullWidth>
                                <AddIcon sx={{mr: 1}}/>Create list
                            </Button>
                        )}
                    </Box>
                )}
            </LoadingLayout>
        </MainLayout>
    );
};

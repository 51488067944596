export function stringToColor(str: string) {
    const hash: number = hashCode(str);
    const hue = hash % 360; // используем хеш для определения оттенка цвета в диапазоне 0-359
    const saturation = hash % 70 + 10; // задаём насыщенность и яркость цвета
    const lightness = hash % 60 + 20; // 20 - 80
    return hslToHex(hue, saturation, lightness)
    // return `hsl(${hue},${saturation}%,${lightness}%)`; // возвращаем цвет в формате HSL
}

function hslToHex(h: number, s: number, l: number) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = (n: number) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

function hashCode(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash |= 0; // преобразовываем в 32-битное целое число
    }
    return Math.abs(hash); // возвращаем абсолютное значение хеш-кода
}

export const getTextColor = (bgColor: string) => {
    // Преобразование цвета фона в формат HSL
    const hsl = toHsl(bgColor);
    // Получение светлоты фона
    const lightness = hsl[2];

    // Определение цвета текста на основе светлоты фона
    return lightness < 0.51 ? "#fff" : "#000";
};

interface rgbColor {
    r: number;
    g: number;
    b: number;
}

const detectColorFormat = (color: string): rgbColor => {
    // Проверка на формат HEX
    if (color.startsWith("#")) {
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);
        return { r, g, b };
    }

    if (color.startsWith("rgb")) {
        const rgbArray: string[] = color.slice(4, -1).split(","); // Удаляем 'rgb(' и ')' и преобразуем в массив
        const r: number = parseInt(rgbArray[0].trim());
        const g: number = parseInt(rgbArray[1].trim());
        const b: number = parseInt(rgbArray[2].trim());
        return { r, g, b };
    }

    throw new Error("Невозможно определить формат цвета");
};
const toHsl = (color: string) => {
    // Преобразование HEX в RGB
    if (color.startsWith("hsl")) {
        const rgbArray: string[] = color.slice(4, -1).split(","); // Удаляем 'rgb(' и ')' и преобразуем в массив
        const h: number = parseInt(rgbArray[0].trim()) / 100;
        const s: number = parseInt(rgbArray[1].trim()) / 100;
        const l: number = parseInt(rgbArray[2].trim()) / 100;
        return [h, s, l];
    }

    let { r, g, b } = detectColorFormat(color);

    (r /= 255), (g /= 255), (b /= 255);

    // Преобразование RGB в HSL
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const l = (max + min) / 2;
    let h, s;

    if (max === min) {
        h = s = 0; // оттенок не имеет значения, если S = 0
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            default:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return [h, s, l];
};

import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "@store/reducers/userSlice";
import { receiptReducer } from "@store/reducers/receiptSlice";
import { userApi } from "@services/user.api";
import { setupListeners } from "@reduxjs/toolkit/query";
import { receiptApi } from "@services/receipt.api";
import { itemApi } from "@services/item.api";
import { menuApi } from "@services/menu.api";
import { listApi } from "@services/list.api";
import { categoryApi } from "@services/category.api";
import { authReducer } from "@store/reducers/authSlice";
import { authApi } from "@services/auth.api";
import authMiddleware from "./auth.middleware";
import { currentPageReducer } from "@store/reducers/currentPageSlice";

export const store = configureStore({
    reducer: {
        userReducer,
        receiptReducer,
        authReducer,
        currentPageReducer,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [receiptApi.reducerPath]: receiptApi.reducer,
        [itemApi.reducerPath]: itemApi.reducer,
        [categoryApi.reducerPath]: categoryApi.reducer,
        [menuApi.reducerPath]: menuApi.reducer,
        [listApi.reducerPath]: listApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(
                    authMiddleware,
                    authApi.middleware,
                    userApi.middleware,
                    receiptApi.middleware,
                    itemApi.middleware,
                    categoryApi.middleware,
                    menuApi.middleware,
                    listApi.middleware,
            ),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

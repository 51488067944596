import { IItem } from "@common/types/item";
import { ICommonNameEntity } from "@common/types/common";
import { CommonStatus } from "@common/types/menu";


export enum ListType {
    NEXT = 'next',
    CUSTOM = 'custom',
}

export interface ICreateList {
    name?: string
    description?: string
    type?: ListType
    menuIds?: string[],
}

export interface IList extends ICommonNameEntity {
    description?: string
    status: CommonStatus
    type: ListType
    items: IItemList[],
}

export interface IItemList {
    id: string,
    status: CommonStatus
    note?: string
    name?: string
    item: IItem,
    tmpCategory: string,
    count?: number | null
}

export interface IListChangeStatus {
    id: string,
    status: CommonStatus
}

export interface IItemListChangeStatus {
    id: string,
    status: CommonStatus
}

export interface IAddListMenu {
    listId: string,
    menuIds: string[]
}

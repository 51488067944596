import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch } from "react-redux";
import { login } from "@store/reducers/authSlice";
import { authApi } from "@services/auth.api";
import { paths } from "../router";
import { useNavigate } from "react-router-dom";
import { Avatar, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link } from '@mui/material';
import { Copyright } from "@components/layouts/copyright.layout";

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { enqueueSnackbar } from "notistack";
import { NotificationLayout } from "@components/layouts/notification/notification.layout";

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const [isError, setIsError] = useState(false);


    const [loginAction] = authApi.useLoginMutation();
    const dispatch = useDispatch();

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(event.target.value.trim());
        setIsError(false);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value.trim());
        setIsError(false);
    };

    const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(event.target.checked);
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (userName === '' || password === '') {
            // одно из полей не заполнено
            return;
        }
        try {
            const { access_token } = await loginAction({ userName, password }).unwrap();
            enqueueSnackbar('Success login!', { variant: 'success' });
            dispatch(login(access_token));
            if (rememberMe) {
                localStorage.setItem('authToken', access_token);
            }
            navigate(paths.home);
        } catch (error: any) {
            setIsError(true);
            enqueueSnackbar(`Failed to login!: ${error.data.message}`, { variant: 'warning' });
            console.warn(error)
        }
    };

    return (
            <Container component="main" maxWidth="xs">
                <NotificationLayout/>
                <CssBaseline/>
                <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="userName"
                                label="User name"
                                name="userName"
                                autoComplete="userName"
                                onChange={handleEmailChange}
                                autoFocus
                                error={isError}
                        />
                        <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handlePasswordChange}
                                error={isError}
                        />
                        <FormControlLabel
                                control={
                                    <Checkbox
                                            name="remember"
                                            value="remember"
                                            color="primary"
                                            checked={rememberMe}
                                            onChange={handleRememberMeChange}
                                    />
                                }
                                label="Remember me"
                        />
                        <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }}/>
            </Container>
    );
};

export default LoginPage;

import { ICommonNameEntity } from "@common/types/common";

export enum UnitEnum {
    KG = "kg",
    PIECE = "piece",
    LITER = "liter",
}

export interface IItem extends ICommonNameEntity {
    unit?: UnitEnum;
    categoryId?: string | null;
    category?: ICategory | null;
}

export interface ICategory extends ICommonNameEntity {
    color?: string;
}

export interface IProductsByCategory {
    category: ICategory,
    products: IItem[]
}

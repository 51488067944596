import { createApi } from "@reduxjs/toolkit/query/react";
import { IAddReceiptMenu, IChangeStatus, ICreateMenu, IMainListMenu, IMenu } from "@common/types/menu";
import { baseQuery } from "@services/baseQuery.api";


const tagType = 'Menu';
export const menuApi = createApi({
    reducerPath: 'menuApi',
    baseQuery,
    tagTypes: [tagType],
    endpoints: (builder) => ({
        getMainMenu: builder.query<IMainListMenu, void>({
            query: () => `menu/main`,
            transformResponse: (rawResult: IMainListMenu) => {
                const [current, ...next] = rawResult?.next || []
                const [near, ...customs] = rawResult?.customs || []
                return { current, next, near, customs }
            },
            providesTags: [tagType]
        }),
        getMenu: builder.query<IMenu[], void>({
            query: (limit?) => ({
                url: 'menu',
                params: { limit }
            }),
            providesTags: [tagType]
        }),
        createMenu: builder.mutation<IMenu, ICreateMenu>({
            query: (menu: ICreateMenu) => ({
                url: 'menu',
                method: 'POST',
                body: menu
            }),
            invalidatesTags: [tagType]
        }),
        changeStatus: builder.mutation<IMenu, IChangeStatus>({
            query: ({ id, status }) => ({
                url: `menu/${id}/changeStatus`,
                method: 'PATCH',
                body: { status }
            }),
            invalidatesTags: [tagType]
        }),
        addReceiptsMenu: builder.mutation<IMenu, IAddReceiptMenu>({
            query: ({ menuId, receiptIds }) => ({
                url: `menu/${menuId}/add`,
                method: 'PATCH',
                body: { receiptIds },
            }),
            invalidatesTags: [tagType]
            // TODO
            // async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
            //     const patchResult = dispatch(
            //             api.util.updateQueryData('getPost', id, (draft) => {
            //                 Object.assign(draft, patch)
            //             })
            //     )
            //     try {
            //         await queryFulfilled
            //     } catch {
            //         patchResult.undo()
            //     }
            // },
        })
    }),
})

import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from "react-router-dom";
import { bottomPages } from "../../router";


export default function FixedBottomNavigation() {

    const location = useLocation();
    const [value, setValue] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        const currentPageIndex = bottomPages.findIndex((page) => page.path === location.pathname);
        if (currentPageIndex >= 0) {
            setValue(currentPageIndex);
        }
    }, [location.pathname]);

    const handleChange = React.useCallback(
            (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
                setValue(newValue);
                const nav = bottomPages[newValue].path;
                navigate(nav);
            },
            [navigate]
    );

    return (
            <Box sx={{ pb: 7 }} ref={ref}>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation
                            showLabels
                            value={value}
                            onChange={handleChange}
                    >
                        {bottomPages.map(({ path, name, icon }) => (
                                <BottomNavigationAction key={path} label={name} icon={icon}/>
                        ))}
                    </BottomNavigation>
                </Paper>
            </Box>
    );
}

import * as React from 'react';
import { MobileNavbarLayout } from "@components/layouts/navbar/mobile-navbar.layout";

export const NavbarLayout: React.FC = () => {
    return (<>
        {/*<PcNavbarLayout/>*/}
        {/*<MobileNavbarLayout/>*/}
        <MobileNavbarLayout/>
    </>);
};



export const isProd = process.env.NODE_ENV === 'production'
export const isDev = process.env.NODE_ENV === 'development'


export const mainConfig = {
    env: process.env.NODE_ENV,
    isProd,
    isDev,
    baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:3000'
}

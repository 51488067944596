import { createApi } from "@reduxjs/toolkit/query/react";
import { ICategory } from "@common/types/item";
import { SaveHelper } from "@common/helpers/save.helper";
import { baseQuery } from "@services/baseQuery.api";

const tagType = "Category";
export const categoryApi = createApi({
    reducerPath: "categoryApi",
    baseQuery,
    tagTypes: [tagType],
    endpoints: (builder) => ({
        getCategories: builder.query<ICategory[], void>({
            query: (limit?) => ({
                url: "categories",
                params: { limit },
            }),
            providesTags: [tagType],
        }),
        saveCategory: builder.mutation<ICategory, Partial<ICategory>>({
            query: (category: ICategory) => SaveHelper.getQuery("categories", category),
            invalidatesTags: [tagType],
        }),
    }),
});

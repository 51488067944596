import { createApi } from "@reduxjs/toolkit/query/react";
import { IUser, IUserCreate } from "@common/types/user";
import { baseQuery } from "@services/baseQuery.api";

// const baseUrl = 'https://jsonplaceholder.typicode.com';

const tagType = 'User';
export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery,
    tagTypes: [tagType],
    endpoints: (builder) => ({
        getUsers: builder.query<IUser[], number | undefined | null>({
            query: (limit?) => ({
                url: 'users',
                params: {
                    limit
                }
            }),
            providesTags: [tagType]
        }),
        createUser: builder.mutation<IUser, IUserCreate>({
            query: (user: IUserCreate) => ({
                url: 'users',
                method: 'POST',
                body: user
            }),
            invalidatesTags: [tagType]
        })
    }),
})

import React, { FC, useEffect, useState } from "react";
import {
  Avatar,
  Container,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { ICategory, IItem, UnitEnum } from "@common/types/item";
import { itemApi } from "@services/item.api";
import { SaveHelper } from "@common/helpers/save.helper";

import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CommonAutocompleteLayout } from "@components/layouts/item-autocomplete.layout";
import { categoryApi } from "@services/category.api";
import { stringToColor } from "@common/hooks/color";

interface IAddItem {
  editItem?: IItem | null;
  cancelAction?: () => void;
}

const defaultCategory: ICategory = {
  id: SaveHelper.addId,
  name: "",
  color: "#793333",
};

const defaultItem: IItem = {
  id: SaveHelper.addId,
  name: "",
  unit: UnitEnum.PIECE,
  category: defaultCategory,
};
export const buttons = {
  add: {
    text: "add",
    color: "success" as const,
    icon: <AddIcon />,
    label: "Add",
  },
  edit: {
    text: "save",
    color: "info" as const,
    icon: <DoneIcon />,
    label: "Edit",
  },
};
export const CreateItem: FC<IAddItem> = ({ editItem, cancelAction }) => {
  const [item, setItem] = useState<Partial<IItem>>(editItem || defaultItem);
  const [category, setCategory] = useState<IItem | null>(null);

  const [saveItem] = itemApi.useSaveItemMutation();
  const { data: categories } = categoryApi.useGetCategoriesQuery();
  const [saveCategory] = categoryApi.useSaveCategoryMutation();

  const fab = editItem ? buttons.edit : buttons.add;

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setItem({ ...item, name: value });
  };

  const changeUnit = (event: SelectChangeEvent) => {
    const unit = event.target.value as UnitEnum;
    setItem({ ...item, unit });
  };
  const createNewItem = async () => {
    try {
      const data = await saveItem({
        ...item,
        categoryId: category?.id,
        category: undefined,
      }).unwrap();
      setItem(data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveCategory = async (category: ICategory | null) => {
    if (category?.id === SaveHelper.addId) {
      category.color = stringToColor(category.name);
      category = await saveCategory(category).unwrap();
    }
    console.log(category); // можно здесь обработать успешное сохранение
    setCategory(category);
  };
  useEffect(() => {
    setItem(editItem || defaultItem);
    setCategory(editItem?.category || null);
  }, [editItem]);
  return (
    <Container sx={{ mt: 5 }}>
      {/*<p>{JSON.stringify(item)}</p>*/}
      <Stack direction="row" spacing={2}>
        <Avatar variant="square" sx={{ bgcolor: item.category?.color }}>
          {item.category?.name[0] || ""}
        </Avatar>
        <TextField value={item.name} onChange={changeName} label="Name" />
        <FormControl sx={{ width: 100 }}>
          <InputLabel>Unit</InputLabel>
          <Select value={item.unit} label="Unit" onChange={changeUnit}>
            {Object.values(UnitEnum).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <CommonAutocompleteLayout
          label="category"
          entity={category}
          entities={categories || []}
          onSelectItem={handleSaveCategory}
        />
        <Stack sx={{ width: 100 }}>
          <Fab onClick={createNewItem} aria-label={fab.label} color={fab.color}>
            {fab.icon}
          </Fab>
          {editItem && (
            <Fab onClick={cancelAction} aria-label="cancel" color="error">
              <ArrowBackIcon />
            </Fab>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

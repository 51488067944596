import React, {FC, PropsWithChildren} from "react";
import {
    Checkbox,
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";

import {IItemList} from "@common/types/list";
import {countItem} from "@components/receipt/list.item.receipt";
// import styles from "@styles/item.list.module.scss";
import "@styles/item.list.scss";
import {listApi} from "@services/list.api";
import {CommonStatus} from "@common/types/menu";
import {enqueueSnackbar} from "notistack";
import {ExpandLess, ExpandMore, ShortText, StarBorder} from "@mui/icons-material";

interface ItemListProps extends PropsWithChildren {
    itemLists: IItemList[];
    changedStatus?: (id: string[], status: CommonStatus) => void;
}

export const ItemList: FC<ItemListProps> = ({itemLists, changedStatus}) => {

    const {id, item} = itemLists[0];
    const [open, setOpen] = React.useState(true);
    const [status, setStatus] = React.useState(itemLists[0].status);
    const [changeStatus] = listApi.useChangeItemStatusMutation();

    React.useEffect(() => {
        if (itemLists) {
            setStatus(itemLists[0].status);
        }
    }, [itemLists]);


    const handleClick = () => {
        setOpen(!open);
    };

    const count = itemLists.reduce((result, itemList: IItemList) => {
        // console.log(itemList);
        if (!itemList.count) {
            return result;
        }
        result += itemList.count;
        return result;
    }, 0);

    const changeStatusAction = async () => {
        const newStatus = status === CommonStatus.DONE ? CommonStatus.ACTIVE : CommonStatus.DONE;
        setStatus(newStatus);
        if (changedStatus) {
            changedStatus(itemLists.map(itemList => itemList.id), newStatus)
        }
        try {
            await Promise.all(itemLists.map(itemList => changeStatus({id: itemList.id, status: newStatus}).unwrap()));
            // await changeStatus({id, status: newStatus}).unwrap();
            enqueueSnackbar('Success to change status!', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Failed to change status!', {variant: 'warning'});
            console.warn(error)
        }
    };
    const labelId = `checkbox-list-label-${id}`;
    // const itemClasses = status === ListItemStatus.DONE ?  : styles.active;
    // const itemClasses = 'done'
    return (<>
            <ListItem className={status}
                      key={id}
                      secondaryAction={
                          <Typography>
                              {countItem(count)}
                          </Typography>
                      }
                      disablePadding
            >
                <ListItemButton role={undefined} onClick={changeStatusAction} dense>
                    <ListItemIcon>
                        <Checkbox edge="start" checked={status == CommonStatus.DONE} tabIndex={-1} disableRipple
                                  inputProps={{"aria-labelledby": labelId}}/>
                    </ListItemIcon>
                    <ListItemText className="cardBody" id={labelId} primary={item.name}/>
                    {/*{itemLists.length > 1 &&*/}
                    {/*    itemLists.map((itemList, index) => (*/}
                    {/*        <ListItemText key={index} className="cardBody" id={labelId} primary={itemList.count}/>*/}
                    {/*    ))*/}
                    {/*}*/}
                    {/*{item.note && <ListItemText className="cardBody" id={labelId} primary={item.note}/>}*/}
                </ListItemButton>

                {itemLists.length > 1 &&
                    <ListItemButton onClick={handleClick}
                                style={{ justifyContent: "center", height: "50px", width: "50px", padding: "5px", flexGrow: 0}}>
                        {open ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                }
            </ListItem>
            {itemLists.length > 1 &&
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {itemLists.map((itemList, index) => (
                            <List key={index} component="div" disablePadding>
                                <ListItemButton sx={{pl: 4, height: '30px', color: 'grey'}}>
                                    <ListItemIcon>
                                        <ShortText/>
                                    </ListItemIcon>
                                    <ListItemText primary={itemList.note}/>
                                </ListItemButton>
                            </List>
                        ))}
                </Collapse>
            }
            {/*<Grid pb={1} container spacing={1}>*/}
            {/*    <Grid item xs="auto">*/}
            {/*        <Tooltip title="category" placement="left-start" arrow>*/}
            {/*            <Avatar sx={{*/}
            {/*                bgcolor: stringToColor(itemList.item.name),*/}
            {/*                width: 24,*/}
            {/*                height: 24*/}
            {/*            }}>*/}
            {/*                {itemList.item.name[0]}*/}
            {/*            </Avatar>*/}
            {/*        </Tooltip>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs>*/}
            {/*        <Typography color="text.primary">{itemList.item.name}</Typography>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs="auto">*/}
            {/*        {countItem(itemList.count)}*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </>
    );
};

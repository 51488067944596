import { IUser, UserState } from "@common/types/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { fetchUsers } from "@store/reducers/actionCreators";

const initialState: UserState = {
    users: [],
    isLoading: false,
    error: null
}
const usersFetching = (state: UserState) => {
    state.error = '';
    state.isLoading = true;
};
const usersFetchingSuccess = (state: UserState, action: PayloadAction<IUser[]>) => {
    state.isLoading = false;
    state.error = '';
    state.users = action.payload;
};
const usersFetchingError = (state: UserState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.error = action.payload;
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        usersFetching,
        usersFetchingSuccess,
        usersFetchingError
    },
    // variant 2
    // extraReducers: {
    //     [fetchUsers.pending.type]: usersFetching,
    //     [fetchUsers.fulfilled.type]: usersFetchingSuccess,
    //     [fetchUsers.rejected.type]: usersFetchingError
    // }
})

// export const {usersFetchingSuccess,usersFetchingError,usersFetching } = userSlice.actions
export const userReducer = userSlice.reducer
